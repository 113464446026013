const DefaultForm = () => {
    return (
      <div>
        <h2>기본 참가 양식</h2>
        <p>해당 이벤트에 대한 양식이 없습니다.</p>
      </div>
    );
  };
  
  export default DefaultForm;
  